.carousel-container {
    position: relative;
    height: 800px; /* Set this to your desired fixed height */
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .carousel-image-container {
    height: 100%;
    width: 100%;
  }
  
  .carousel-image {
    object-fit: cover;
    object-position: center;
  }
  
  .carousel-blurred-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
  }
  
  .blur-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: blur(10px);
    transform: scale(1.1);
  }
  
  .carousel.slide {
    position: relative;
    z-index: 1;
    height: 100%;
  }
  
  .carousel-inner img.scaled-image {
    height: 100%;
    width: auto; /* Auto width to maintain aspect ratio */
    object-fit: contain; /* Ensures the image scales correctly within fixed height */
    transition: transform 0.5s ease; /* Smooth scaling transition */
  }
  
  .carousel-inner img.scaled-image:hover {
    transform: scale(1.05); /* Slight hover effect */
  }
  
  .carousel-inner, .carousel-item {
    height: 100%;
  }
